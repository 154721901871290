import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles/Container'

const EvaluationThankYou = () => (
  <Layout>
    <SEO title="Thank You for Requesting an Evaluation" />
    <Container>
      <h1>Thank You</h1>
      <p>
        Thank you for requesting a free security evaluation! We'll be in touch
        shortly to start the process.
      </p>
    </Container>
  </Layout>
)

export default EvaluationThankYou
